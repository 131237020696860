/**
 * @flow
 * @relayHash 263a9a0729b3c65ad9f9ae97aff7febf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSettingsDialog_me$ref = any;
export type UserSettingsDialogQueryVariables = {|
  mounted: boolean
|};
export type UserSettingsDialogQueryResponse = {|
  +me: ?{|
    +$fragmentRefs: UserSettingsDialog_me$ref
  |}
|};
export type UserSettingsDialogQuery = {|
  variables: UserSettingsDialogQueryVariables,
  response: UserSettingsDialogQueryResponse,
|};
*/


/*
query UserSettingsDialogQuery(
  $mounted: Boolean!
) {
  me {
    ...UserSettingsDialog_me_1MqO2V
    id
  }
}

fragment UserSettingsDialog_me_1MqO2V on User {
  id
  photoURL @include(if: $mounted)
  displayName @include(if: $mounted)
  email @include(if: $mounted)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "mounted",
    "type": "Boolean!",
    "defaultValue": null
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserSettingsDialogQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserSettingsDialog_me",
            "args": [
              {
                "kind": "Variable",
                "name": "mounted",
                "variableName": "mounted"
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserSettingsDialogQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "Condition",
            "passingValue": true,
            "condition": "mounted",
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "photoURL",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "displayName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserSettingsDialogQuery",
    "id": null,
    "text": "query UserSettingsDialogQuery(\n  $mounted: Boolean!\n) {\n  me {\n    ...UserSettingsDialog_me_1MqO2V\n    id\n  }\n}\n\nfragment UserSettingsDialog_me_1MqO2V on User {\n  id\n  photoURL @include(if: $mounted)\n  displayName @include(if: $mounted)\n  email @include(if: $mounted)\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd64f5577f776241848fd5616cd2ad748';
module.exports = node;
