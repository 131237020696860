import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

export default [
  {
    path: '/:username',
    query: graphql`
      query profileQuery($username: String!) {
        ...Layout_data
        me {
          ...Profile_me
        }
        user(username: $username) {
          displayName
          ...Profile_user
        }
      }
    `,
    components: () => [import(/* webpackChunkName: 'profile' */ './Profile')],
    render: ([Profile], data, ctx) =>
      data.user
        ? {
            title: `${data.user.displayName}`,
            component: (
              <Layout data={data}>
                <Profile
                  me={data.me}
                  user={data.user}
                  path={ctx.path}
                  query={ctx.query}
                />
              </Layout>
            ),
            chunks: ['profile'],
          }
        : undefined,
  },
];
