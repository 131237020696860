import React from 'react';
import { graphql } from 'relay-runtime';
import Layout from '../common/Layout';

export default [
  {
    app: 'remedy',
    path: '',
    query: graphql`
      query remedyQuery {
        ...Layout_data
        ...RHome_data
      }
    `,
    components: () => [import(/* webpackChunkName: 'remedy.home' */ './RHome')],
    render: ([RHome], data, ctx) => ({
      title: `${ctx.config.app.name}`,
      component: (
        <Layout data={data}>
          <RHome data={data} />
        </Layout>
      ),
      chunks: ['remedy.home'],
    }),
  },
];
